import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "querystringify";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useAuth } from "../AuthContext";

import CenteredPaperLayout from "./CenteredPaperLayout";
import AbovePaperLogo from "../components/AbovePaperLogo";
import userAccountFragment from "../fragments/userAccount";
import requiredEnvironmentVariable from "../requiredEnvironmentVariable";
const idFastviewLinkPath = requiredEnvironmentVariable(
  "REACT_APP_ID_FV_LINK_URL"
);

const ExchangeSessionTokenMutation = gql`
  mutation ExchangeSessionToken($tenantId: ID) {
    users {
      exchangeSessionToken(defaultTenantId: $tenantId) {
        success
        user {
          id
          name
          email
        }
        account {
          ...userAccount
        }
        token
        expiresIn
      }
    }
  }

  ${userAccountFragment}
`;

const defaultSearchRedirect = (location) => ({
  continue: `${location.pathname}${location.search}`,
});

const RequireAuth = ({
  redirectPathname,
  redirectSearch,
  roles,
  children,
  skipAccountCheck = false,
}) => {
  const [exchangeSessionToken] = useMutation(ExchangeSessionTokenMutation);

  const location = useLocation();
  const { signIn: setSignIn, isAuthenticated, isInRole, account } = useAuth();

  useEffect(() => {
    console.log("RequireAuth effect");

    let unmounted = false;

    exchangeSessionToken({
      variables: {
        tenantId: localStorage.getItem("tenant_id"),
      },
    })
      .then(
        ({
          data: {
            users: { exchangeSessionToken },
          },
        }) => {
          if (unmounted) return;

          console.log("Exchanged session token", exchangeSessionToken);
          if (!exchangeSessionToken.token) {
            throw new Error("Invalid username or password");
          }

          return setSignIn({
            ...exchangeSessionToken,
          });
        }
      )
      .catch((err) => {
        console.error(err);
        window.location.replace(
          `${idFastviewLinkPath}login?nsr=1&returnTo=${encodeURIComponent(
            window.location.origin
          )}`
        );
      });

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    console.log("isAuthenticated changed", isAuthenticated);
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <CenteredPaperLayout title="Authenticating" above={<AbovePaperLogo />}>
        <h1>Authenticating...</h1>
      </CenteredPaperLayout>
    );
  }

  if (!skipAccountCheck && isAuthenticated && account == null) {
    return <Redirect to="/no-accounts" />;
  }

  return isAuthenticated ? (
    isInRole(roles) ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: redirectPathname || "/",
        }}
      />
    )
  ) : (
    <Redirect
      to={{
        pathname: idFastviewLinkPath || "/login",
        search: qs.stringify(
          {
            ...(redirectSearch || defaultSearchRedirect)(location),
          },
          true
        ),
      }}
    />
  );
};

export default RequireAuth;
