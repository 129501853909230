import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import GoogleMap from "../containers/GoogleMap";
import { Marker, Polyline } from "@react-google-maps/api";
import { Query as VehicleTrackingQuery } from "../containers/VehicleTrackingQuery";
import { useQuery } from "@apollo/client";
import { red } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const DEFAULT_CENTER = { lat: 53.15, lng: -1.58 };

const IncidentCoreDetailsMap = ({ incident }) => {
  const [markerPositon, setMarkerPositon] = useState(null);
  const [map, setMap] = useState(null);

  const dateRange = useMemo(() => {
    const occurredAt = incident?.occurredAt || moment();
    return {
      date: moment(occurredAt).format("YYYY-MM-DD"),
      start: moment(occurredAt).startOf("day").format(),
      end: moment(occurredAt).endOf("day").format(),
    };
  }, [incident?.occurredAt]);

  const { loading, data: trackingData } = useQuery(VehicleTrackingQuery, {
    variables: {
      id: incident?.vehicle?.id,
      filter: { from: dateRange.start, until: dateRange.end },
    },
    fetchPolicy: "cache-and-network",
    skip: !incident?.vehicle?.id,
  });
  const markers = trackingData?.node?.journey?.markers || [];

  const trackingPath = useMemo(
    () => markers?.map((node) => node.coord) || [],
    [markers]
  );

  const loadHandler = (mapInstance) => {
    fitBounds(mapInstance);
    setMap(mapInstance);
  };

  const fitBounds = (mapInstance) => {
    if (markers.length === 0) {
      mapInstance.setCenter(DEFAULT_CENTER);
      mapInstance.setZoom(6);
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((node) => {
        bounds.extend(node.coord);
      });

      mapInstance.fitBounds(bounds);
    }

    const [latStr, lngStr] = incident?.location.split(",");
    const isValidLocation =
      latStr && lngStr && !isNaN(latStr) && !isNaN(lngStr);

    if (isValidLocation) {
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);
      var positon = { lat, lng };
      setMarkerPositon(positon);

      var listener = window.google.maps.event.addListener(
        mapInstance,
        "idle",
        function () {
          mapInstance.setCenter(positon);
          mapInstance.setZoom(15);
          window.google.maps.event.removeListener(listener);
        }
      );
    }
  };

  useEffect(() => {
    if (map && !loading) {
      fitBounds(map);
    }
  }, [map, loading]);

  return (
    <Box sx={{ position: "relative" }}>
      {loading && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}

      <GoogleMap loadHandler={loadHandler} options={{ mapTypeControl: false }}>
        <Polyline
          path={trackingPath}
          options={{
            strokeWeight: 6,
            strokeColor: red[900],
            icons: [
              {
                offset: "100%",
                icon: {
                  path: "M -2,0 0,-2 2,0 0,2 z",
                  strokeColor: "#F00",
                  fillColor: "#F00",
                  scale: 3,
                },
              },
            ],
          }}
        />
        {trackingData?.stops?.map((item, idx) => (
          <Marker key={`stop-${idx}`} position={item.coord} />
        ))}
        {markerPositon && <Marker position={markerPositon} />}
      </GoogleMap>
    </Box>
  );
};

export default IncidentCoreDetailsMap;
