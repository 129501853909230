import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import MarkdownIt from "markdown-it";
import RequestDriverAccountButton from "../containers/RequestDriverAccountButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AdapterLink from "../components/form/Link";
import AnchorLink from "@mui/material/Link";
import IncidentCoreDetailsMap from "./IncidentCoreDetailsMap";

const markdown = new MarkdownIt();

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const IncidentCoreDetails = ({ incident }) => {
  const classes = useStyles();

  const DriverDetails = ({ driver }) =>
    driver && (
      <div>
        <p>
          Driver: {driver.name}
          {driver.email && (
            <AnchorLink href={`mailto:${driver.email}`}>
              <br />
              {driver.email}
            </AnchorLink>
          )}
          {driver.telephone && (
            <AnchorLink href={`tel:${driver.telephone}`}>
              <br />
              {driver.telephone}
            </AnchorLink>
          )}
        </p>
        <div>
          Account requested:{" "}
          {driver?.accountRequestedOn
            ? moment(driver.accountRequestedOn).calendar()
            : "No"}
          <RequestDriverAccountButton
            incidentId={incident.id}
            accountRequestedOn={driver?.accountRequestedOn}
          />
        </div>
      </div>
    );

  return (
    <Grid container spacing={2} className={classes.paper}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <p>
              Vehicle:{" "}
              <AnchorLink
                component={AdapterLink}
                to={`/vehicles/${incident?.vehicleId}`}
              >
                {incident?.vehicle?.vrm}
              </AnchorLink>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DriverDetails driver={incident?.driver} />
          </Grid>
        </Grid>

        <Divider />
        <p>Occurred at: {moment(incident?.occurredAt).format("LLL")}</p>
        <p>Location: {incident?.location}</p>

        <Divider />
        <p>Details of incident:</p>
        <blockquote
          dangerouslySetInnerHTML={{
            __html: markdown.render(incident?.accountOfIncident || ""),
          }}
        />
        <p>Vehicle Speed: {incident?.vehicleSpeed || "N/A"}</p>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={4}>
        <IncidentCoreDetailsMap incident={incident} />
      </Grid>
    </Grid>
  );
};

export default IncidentCoreDetails;
