export const UNKNOWN = "UNKNOWN";

export const NEARSIDE = "NEARSIDE";
export const FORWARD = "FORWARD";
export const OFFSIDE = "OFFSIDE";
export const REAR = "REAR";
export const BLIND_SPOT = "BLIND_SPOT";
export const BLIND_SPOT_LEFT = "BLIND_SPOT_LEFT";
export const BLIND_SPOT_RIGHT = "BLIND_SPOT_RIGHT";
export const DRIVER_CAB = "DRIVER_CAB";
export const LEFT_VIEW = "LEFT_VIEW";
export const RIGHT_VIEW = "RIGHT_VIEW";
export const NEARSIDE_FRONT = "NEARSIDE_FRONT";
export const OFFSIDE_FRONT = "OFFSIDE_FRONT";
export const NEARSIDE_REAR = "NEARSIDE_REAR";
export const OFFSIDE_REAR = "OFFSIDE_REAR";
export const TRAILER = "TRAILER";
export const TRAILER_BED = "TRAILER_BED";
export const REAR_COMPARTMENT = "REAR_COMPARTMENT";
export const FRONT_SALOON = "FRONT_SALOON";
export const REAR_SALOON = "REAR_SALOON";
export const MID_SALOON = "MID_SALOON";
export const REAR_DOOR = "REAR_DOOR";
export const FRONT_DOOR = "FRONT_DOOR";
export const UPPER_DECK_FRONT = "UPPER_DECK_FRONT";
export const UPPER_DECK_REAR = "UPPER_DECK_REAR";
export const UPPER_DECK_STAIRS = "UPPER_DECK_STAIRS";
export const LOWER_DECK_FRONT = "LOWER_DECK_FRONT";
export const LOWER_DECK_REAR = "LOWER_DECK_REAR";
export const LOWER_DECK_STAIRS = "LOWER_DECK_STAIRS";

export const MOIS = "MOIS";

export const All = [
  NEARSIDE,
  FORWARD,
  OFFSIDE,
  REAR,
  BLIND_SPOT,
  BLIND_SPOT_LEFT,
  BLIND_SPOT_RIGHT,
  DRIVER_CAB,
  LEFT_VIEW,
  RIGHT_VIEW,
  NEARSIDE_FRONT,
  OFFSIDE_FRONT,
  NEARSIDE_REAR,
  OFFSIDE_REAR,
  TRAILER,
  TRAILER_BED,
  REAR_COMPARTMENT,
  FRONT_SALOON,
  REAR_SALOON,
  MID_SALOON,
  UPPER_DECK_FRONT,
  UPPER_DECK_REAR,
  UPPER_DECK_STAIRS,
  LOWER_DECK_FRONT,
  LOWER_DECK_REAR,
  LOWER_DECK_STAIRS,
  REAR_DOOR,
  FRONT_DOOR,

  MOIS,
];

export const mapToDisplay = (key) => {
  if (key === NEARSIDE) return "Nearside";
  if (key === FORWARD) return "Forward";
  if (key === OFFSIDE) return "Offside";
  if (key === REAR) return "Rear";
  if (key === BLIND_SPOT) return "Blind Spot";
  if (key === BLIND_SPOT_LEFT) return "Blind Spot Left";
  if (key === BLIND_SPOT_RIGHT) return "Blind Spot Right";
  if (key === DRIVER_CAB) return "Driver Cab";
  if (key === LEFT_VIEW) return "Left View";
  if (key === RIGHT_VIEW) return "Right View";
  if (key === NEARSIDE_FRONT) return "Nearside Front";
  if (key === OFFSIDE_FRONT) return "Offside Front";
  if (key === NEARSIDE_REAR) return "Nearside Rear";
  if (key === OFFSIDE_REAR) return "Offside Rear";
  if (key === TRAILER) return "Trailer";
  if (key === TRAILER_BED) return "Trailer Bed";
  if (key === REAR_COMPARTMENT) return "Rear Compartment";
  if (key === FRONT_SALOON) return "Front Saloon";
  if (key === REAR_SALOON) return "Rear Saloon";
  if (key === MID_SALOON) return "Mid Saloon";
  if (key === UPPER_DECK_STAIRS) return "Upper Deck Stairs";
  if (key === UPPER_DECK_FRONT) return "Upper Deck Front";
  if (key === UPPER_DECK_REAR) return "Upper Deck Rear";
  if (key === LOWER_DECK_FRONT) return "Lower Deck Front";
  if (key === LOWER_DECK_REAR) return "Lower Deck Rear";
  if (key === LOWER_DECK_STAIRS) return "Lower Deck Stairs";
  if (key === REAR_DOOR) return "Rear Door";
  if (key === FRONT_DOOR) return "Front Door";
  if (key === MOIS) return "MOIS";

  if (key === UNKNOWN) return "Unknown";

  return key;
};
