import gql from "graphql-tag";

const INCIDENT_FRAGMENT = gql`
  fragment incident on Incident {
    id
    status
    reference
    claim {
      id
      reference
      status
    }
    closedReason
    occurredAt
    location
    vehicleId
    vehicle {
      id
      vrm
    }
    driverId
    driver {
      id
      name
      email
      telephone
    }
    driverAccount {
      account
      requestedOn
      translation {
        text
      }
    }
    accountOfIncident
    vehicleSpeed
    thirdParties {
      thirdPartiesInvolved
      thirdParties {
        detailsOfDamage
        email
        driverName
        ownerName
        address
        telephoneNumber
        vehicleMake
        vehicleRegistration
        insurer {
          name
          policyNumber
        }
        injuries {
          any
          details
          hospitalName
          takenToHospital
        }
      }
    }
    footageRequests {
      status
      phase
      cameras
      jobPercentComplete
      footageStartAt
      footageEndAt
      createdAt
      createdByUser {
        id
        name
      }
    }
    attachedEvidence {
      key
      type
      status
      description
      source {
        contentType
        src
      }
    }
    policeDetails {
      policeInvolved
      incidentNumber
      policeOfficerName
    }
    witnesses {
      witnesses {
        name
        contactNumber
        address
        emailAddress
      }
    }
    propertyDamage {
      detail
    }
  }
`;

export default INCIDENT_FRAGMENT;
