import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import AdapterLink from "../components/form/Link";
import { Field } from "react-final-form";
import TextField from "../components/form/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import requiredEnvironmentVariable from "../requiredEnvironmentVariable";
const idFastviewLinkPath = requiredEnvironmentVariable(
  "REACT_APP_ID_FV_LINK_URL"
);

const validateRequired = (fieldName) => (value) =>
  !value && `${fieldName} is required`;

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: `${theme.spacing(4)} auto ${theme.spacing(2)} auto`,
  },
  version: {
    color: theme.palette.text.hint,
    fontSize: "0.8em",
  },
}));

const SignUpForm = ({ error, disabled, authenticating, isMigrated }) => {
  const classes = useStyles();

  const routeChange = () => {
    window.location.replace(
      `${idFastviewLinkPath}login?returnTo=${encodeURIComponent(
        window.location.origin
      )}`
    );
  };

  return (
    <div>
      <p>We need a few details to create an account.</p>
      <Field
        component={TextField}
        name="fullName"
        required
        validate={validateRequired("Full name")}
        margin="normal"
        label="Full name"
        fullWidth
        disabled={disabled}
        autoFocus
      />
      <Field
        component={TextField}
        name="email"
        type="email"
        required
        validate={validateRequired("Email")}
        margin="normal"
        label="Email address"
        fullWidth
        disabled={disabled}
      />
      {isMigrated ? (
        <Typography align="left" gutterBottom>
          You already the account
        </Typography>
      ) : (
        <Field
          component={TextField}
          name="password"
          type="password"
          required
          validate={validateRequired("Password")}
          margin="normal"
          autoComplete="off"
          label="Password"
          fullWidth
        />
      )}

      <div>
        <Button
          disabled={disabled}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Sign up
        </Button>

        {error && (
          <FormHelperText error sx={{ marginTop: 2 }}>
            {error}
          </FormHelperText>
        )}
        <p>
          <Button size="small" onClick={() => routeChange()}>
            Already have an account?
          </Button>
        </p>
        {authenticating && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}
      </div>
    </div>
  );
};

SignUpForm.propTypes = {
  disabled: PropTypes.bool,
  authenticating: PropTypes.bool,
  error: PropTypes.string,
};

export default SignUpForm;
