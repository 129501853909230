import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import GoogleMap from "../containers/GoogleMap";
import { MarkerClusterer, Marker } from "@react-google-maps/api";

import Overlayview from "./Overlayview";

const LiveMap = ({ allVehicles }) => {
  const [mapRef, setMapRef] = useState(null);
  const [expand, setExpand] = useState(false);
  const [fitBoundsCalled, setFitBoundsCalled] = useState(false);

  const trackingPath = useMemo(() => {
    return (allVehicles?.edges ?? []).flatMap((edge) =>
      edge.node.live?.coord ? [edge.node.live.coord] : []
    );
  }, [allVehicles]);

  useEffect(() => {
    if (mapRef && trackingPath.length > 0 && !fitBoundsCalled) {
      fitBounds(mapRef);
      setFitBoundsCalled(true);
    }
  }, [mapRef, trackingPath, fitBoundsCalled]);

  const fitBounds = (mapInstance) => {
    if (mapInstance && trackingPath.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      trackingPath.forEach((item) => {
        bounds.extend(item);
      });

      mapInstance && mapInstance.fitBounds(bounds);

      if (mapInstance) {
        var listener = google.maps.event.addListener(
          mapInstance,
          "idle",
          function () {
            if (mapInstance.getZoom() > 16) mapInstance.setZoom(16);
            google.maps.event.removeListener(listener);
          }
        );
      }
    }
  };

  const loadHandler = (mapInstance) => {
    if (!fitBoundsCalled && trackingPath.length > 0) {
      fitBounds(mapInstance);
      setFitBoundsCalled(true);
    }
    setMapRef(mapInstance);
  };

  const handleOpenExpansion = (vrm) =>
    expand === vrm ? setExpand(false) : setExpand(vrm);

  const handleCloseExpansion = () => setExpand(false);

  const markers = (clusterer) =>
    (allVehicles?.edges || [])
      .filter(({ node: { live } }) => live.coord)
      .map(({ cursor, node: { vrm, live, driver, id } }) => {
        const title = `${vrm} ${driver && ` :: ${driver.name}`}`;

        return (
          <Marker
            key={cursor}
            position={live.coord}
            title={title}
            icon={{
              url: "/icons/my_location.png",
              fillOpacity: 0.8,
              anchor: new window.google.maps.Point(17.5, 17.5),
              scaledSize: new window.google.maps.Size(35, 35),
            }}
            clusterer={clusterer}
          >
            {((clusterer || {}).clusters ?? []).map((e) => {
              if (e.markers.length === 1 && e.markers[0].title === title)
                return (
                  <Overlayview
                    key={cursor}
                    id={id}
                    live={live}
                    expand={expand}
                    vrm={vrm}
                    handleOpenExpansion={handleOpenExpansion}
                    driver={driver}
                    handleCloseExpansion={handleCloseExpansion}
                  />
                );
            })}
          </Marker>
        );
      });

  return (
    <GoogleMap loadHandler={loadHandler} options={{ mapTypeControl: true }}>
      <div>
        {/* <Marker position={center ?? defaultCenter} title={"CENTER"} /> */}
        {allVehicles && (
          <MarkerClusterer averageCenter enableRetinaIcons>
            {(clusterer) => markers(clusterer)}
          </MarkerClusterer>
        )}
      </div>
    </GoogleMap>
  );
};

LiveMap.propTypes = {
  allVehicles: PropTypes.object,
};

export default LiveMap;
