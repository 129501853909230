import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import DepotsIcon from "@mui/icons-material/DeviceHub";
import DriversIcon from "@mui/icons-material/Person";

import RoutedTabs from "../components/RoutedTabs";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import {
  Typography,
  CircularProgress,
  Button,
  LinearProgress,
  AppBar,
  Tab,
  Tooltip,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import VehicleIncidents from "../containers/VehicleIncidents";
import VehicleLiveTab from "../containers/VehicleLiveTab";
import VehicleReplayADay from "../containers/VehicleReplayADay";
import OnlineStatusIcon from "../components/OnlineStatusIcon";

import DvrHealthIcon from "../components/DvrHealthIcon";
import CameraHealthIcon from "../components/CameraHealthIcon";
import NewIncidentOnClick from "../components/NewIncidentOnClick";

const QUERY = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ...Vehicle_vehicle
    }
  }

  fragment Vehicle_vehicle on Vehicle {
    id
    hasVideoSupport
    viewerCanViewCameraLiveStream
    viewerCanViewCameraHistoricStream
    vrm
    vin
    depot {
      id
      name
    }
    driver {
      name
    }
    driverId
    deviceId
    live {
      online
      driver {
        name
        id
      }
      video {
        camera
        label
        cameraId
        streams {
          type
          contentType
          source
        }
      }
    }
    device {
      health {
        status
        dvr {
          status
        }
        cameras {
          camera
          status
          asAt
          since
        }
      }
      dataUsage {
        monthlyAllocation
        monthToDate {
          total
          lastUpdated
        }
      }
    }
  }
`;

export { QUERY };

const TabContainer = ({ children }) => {
  return (
    <Typography
      component="div"
      style={{
        paddingTop: 8 * 3,
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const DepotDiv = styled("div")(({ theme }) => ({
  ...theme.typography.caption,
  display: "flex",
  alignItems: "end",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
}));

const DriverDiv = styled("div")(({ theme }) => ({
  ...theme.typography.caption,
  display: "flex",
  alignItems: "end",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(3),
}));

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(4),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  actionButtonsContainer: {
    display: "flex",
    flexGrow: 1,
  },
  actionButton: {
    marginLeft: "auto",
  },
  flexBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  dvrIcon: {
    marginRight: theme.spacing(4),
  },
  cameraIcon: {
    marginRight: theme.spacing(0.5),
  },
  verticalCenterContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));

const Vehicle = ({ id, incidentsEnabled }) => {
  const history = useHistory();
  const classes = useStyles();

  const { data, loading, error } = useQuery(QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  if (loading && (!data || !data.node)) return <LinearProgress />;
  if (error) return JSON.stringify(error);

  if (!data || !data.node) {
    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Vehicle
        </Typography>
        <p>
          The vehicle could not be found or you do not have permission to view
          it.
        </p>
      </div>
    );
  }

  const { node: vehicle } = data;

  let dataTabSelectors = [
    { tab: 0, path: `/vehicles/${id}/live` },
    { tab: 1, path: `/vehicles/${id}/replay` },
  ];

  if (incidentsEnabled) {
    dataTabSelectors.push({ tab: 2, path: `/vehicles/${id}/incidents` });
  }

  return (
    <div className={classes.flexBox}>
      <Typography variant="h5" className={classes.title} gutterBottom>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={7} className={classes.flexCenter}>
            <div className={classes.flexCenter}>
              <div>
                <Box>{vehicle.vrm}</Box>
                {vehicle.vin && (
                  <Box sx={{ color: "text.secondary", typography: "caption" }}>
                    VIN: {vehicle.vin}
                  </Box>
                )}
              </div>
              <OnlineStatusIcon
                online={vehicle.live.online}
                className={classes.marginLeft}
              />
              {vehicle.hasVideoSupport && (
                <div
                  className={classNames(
                    classes.verticalCenterContainer,
                    classes.marginLeft
                  )}
                >
                  <DvrHealthIcon
                    dvr={vehicle.device.health.dvr}
                    className={classes.dvrIcon}
                  />
                  {vehicle.device.health.cameras.map((c, idx) => (
                    <CameraHealthIcon
                      key={`camera${idx}`}
                      className={classes.cameraIcon}
                      camera={c}
                    />
                  ))}
                </div>
              )}
              {loading && (
                <div>
                  <CircularProgress size={24} />
                </div>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            className={classNames(classes.flexCenter, classes.depotGridItem)}
          >
            {vehicle.driver?.name && (
              <>
                <DriverDiv>
                  <DriversIcon titleAccess="Driver assigned to vehicle" />
                  {vehicle.driver.name}
                </DriverDiv>
              </>
            )}
            <DepotDiv>
              <DepotsIcon titleAccess="Depot of vehicle" />
              {vehicle.depot?.name}
            </DepotDiv>
          </Grid>

          {incidentsEnabled && (
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              className={classNames(classes.flexCenter)}
            >
              <div className={classes.actionButtonsContainer}>
                <NewIncidentOnClick defaultVehicle={vehicle}>
                  {({ onClick }) => (
                    <Tooltip title="Create a new incident for this vehicle">
                      <Button
                        size="small"
                        color="primary"
                        onClick={onClick}
                        className={classes.actionButton}
                      >
                        New incident
                      </Button>
                    </Tooltip>
                  )}
                </NewIncidentOnClick>
              </div>
            </Grid>
          )}
        </Grid>
      </Typography>

      <AppBar position="static">
        <RoutedTabs
          location={history.location}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="inherit"
          scrollButtons="auto"
          allowScrollButtonsMobile
          selectors={dataTabSelectors}
        >
          <Tab label="Live" component={Link} to={`/vehicles/${id}/live`} />
          <Tab
            label="Replay a day"
            component={Link}
            to={`/vehicles/${id}/replay`}
          />

          {incidentsEnabled && (
            <Tab
              label="Incidents"
              component={Link}
              to={`/vehicles/${id}/incidents`}
            />
          )}
        </RoutedTabs>
      </AppBar>
      <Switch>
        <Route
          path={`/vehicles/${id}/live`}
          render={() => (
            <TabContainer>
              <VehicleLiveTab
                vehicleId={id}
                vehicle={vehicle}
                supportsVideo={
                  vehicle.hasVideoSupport &&
                  vehicle.viewerCanViewCameraLiveStream
                }
              />
            </TabContainer>
          )}
        />
        <Route
          path={`/vehicles/${id}/replay`}
          render={() => (
            <TabContainer>
              <VehicleReplayADay
                id={id}
                incidentsEnabled={incidentsEnabled}
                supportsVideo={
                  vehicle.hasVideoSupport &&
                  vehicle.viewerCanViewCameraHistoricStream
                }
              />
            </TabContainer>
          )}
        />

        {incidentsEnabled && (
          <Route
            path={`/vehicles/${id}/incidents`}
            render={() => (
              <TabContainer>
                <VehicleIncidents vehicleId={id} />
              </TabContainer>
            )}
          />
        )}
        <Route render={() => <Redirect to={`/vehicles/${id}/live`} />} />
      </Switch>
    </div>
  );
};

Vehicle.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Vehicle;
